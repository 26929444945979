import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import { HTMLContent } from '../components/Content'
import ContentPadding from '../components/ContentPadding'

import remark from 'remark'
import recommended from 'remark-preset-lint-recommended';
import remarkHtml from 'remark-html'

import Img from 'gatsby-image'

import Helmet from 'react-helmet'

export const LongFormPageSection = ({ image, imagePosition, imageFilter, content, minHeight, bgColor, textColor }) => {
  //image stuff
  var sectionStyle = {};
  if (minHeight) {
   sectionStyle.minHeight = minHeight + 'vh';
  }
  
  var imageDiv = null;
  if (image) {
    var divStyle = {
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%'
            }
            
    var imageStyle = {
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
            }
            
    if (imageFilter && imageFilter !== 'none') {
      divStyle.filter = imageFilter;
    }
            
    if (imagePosition === 'fixed') {
      console.log(image.childImageSharp.fluid.src);
      divStyle.backgroundImage = 'url(' + image.childImageSharp.fluid.src + ')';
      divStyle.backgroundAttachment= 'fixed';
      divStyle.backgroundPosition = 'center';
      divStyle.backgroundSize = 'cover';
    } else if (imagePosition === 'sticky') {
      imageStyle.position = 'sticky';
      imageStyle.height = minHeight + 'vh';
    }

    imageDiv = <div style={divStyle}>
                {imagePosition !== 'fixed' ? <Img
                  fluid={image.childImageSharp.fluid}
                  className={`longform-section-image longform-section-image-${imagePosition}`}
                  style={imageStyle}
                /> : null}
              </div>
  } else {
    if (bgColor) {
      sectionStyle.backgroundColor = bgColor;
    }
  }
  
  var contentStyle = {};
  if (bgColor) {
    contentStyle.backgroundColor = bgColor;
  }
  
  if (textColor) {
    contentStyle.color = textColor;
  }
  
  return (
    <div className={`longform-section longform-section-${imagePosition}`} style={sectionStyle} >
        {imageDiv}
        <ContentPadding>
        <HTMLContent className="content has-text-justified" content={content} style={contentStyle}/>
        </ContentPadding>
    </div>
  )
}

const LongFormPage = ({ data }) => {
  const page = data.page;
  const posts = data.posts.edges.map(({ node: post }) => post);
  
  return (
    <Layout>
      <Helmet title={`Voices Across Time | ${page.frontmatter.title}`} />
      {page.frontmatter.sections.map((section, i) => {
        const content = remark().use(recommended).use(remarkHtml).processSync(section.content).toString();
        
        return <LongFormPageSection key={i}
          image={section.image}
          imagePosition={section.image_position}
          imageFilter={section.image_filter}
          minHeight={section.min_height}
          content={content}
          bgColor={section.bg_color}
          textColor={section.text_color}
        />
      })}
      <ContentPadding>
        <section className="section">
          <div className="columns is-centered is-multiline">
          {posts
            .map((post, i) => (
              <div className="column is-one-third" key={post + post.id}>
                <div className="card" style={{height: "100%"}}>
                  <Link className="card-image" to={post.fields.slug}>
                    <Img fluid={post.frontmatter.small_image.childImageSharp.fluid} />
                  </Link>
                  <div className="card-content blog-card-content">
                    <div className="media">
                      <div className="media-content">
                        <p className="title is-size-4"><Link className="has-text-primary" to={post.fields.slug}>
                          {post.frontmatter.title}
                        </Link></p>
                        <p className="subtitle is-size-6">{post.frontmatter.date}</p>
                      </div>
                    </div>
                    <div className="content has-text-justified">
                      {post.excerpt}
                      
                      <Link className="button is-small has-background-highlight has-text-white is-pulled-right" to={post.fields.slug}>Read More</Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      </ContentPadding>
    </Layout>
  )
}

export default LongFormPage

export const longFormPageQuery = graphql`
  query LongFormPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        sections {
          image {
            childImageSharp {
              fluid(maxWidth: 2000, quality: 75, toFormat: JPG) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_position
          image_filter
          content
          min_height
          text_color
          bg_color
        }
      }
    }
    posts: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "blog-post" }, outreach_show: { eq: true }} }
      limit: 6
    ) {
      edges {
        node {
          excerpt(pruneLength: 200)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "MMMM DD, YYYY")
            small_image {
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 300, quality: 75, toFormat: JPG) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
